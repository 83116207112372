import React, { useEffect, useRef, useState } from 'react';
import '../styles/CareerPath.css';

const Timeline = ({ careerPathSectionRef, homeRef, profileSectionRef, projectsSectionRef }) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [activeElements, setActiveElements] = useState([]);
  const [relativeScrollPos, setRelativeScrollPos] = useState(0);

  const agTimelineRef = useRef(null);
  const agTimelineLineRef = useRef(null);
  const agTimelineLineProgressRef = useRef(null);
  const agTimelineItemRefs = useRef([null]); //Array of balls of years
  const agTimelinePointRefs = useRef([null]);
  const thirdSectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const homeSectionSize = homeRef.current.getBoundingClientRect();
    const profileSectionSize = profileSectionRef.current.getBoundingClientRect();
    const projectsSectionSize = projectsSectionRef.current.getBoundingClientRect();
    const homeSectionHeight = homeSectionSize.height;
    const profileSectionHeight = profileSectionSize.height;
    const projectsSectionHeight = projectsSectionSize.height;

    const totalHeightOfFirstTwoSections = homeSectionHeight + profileSectionHeight + projectsSectionHeight;

    setRelativeScrollPos(Math.max(0, scrollPos + 100 - totalHeightOfFirstTwoSections));

    const agTimelineLineProgress = agTimelineLineProgressRef.current;
    agTimelineLineProgress.style.height = relativeScrollPos + 30 + "px";
  }, [homeRef, profileSectionRef, careerPathSectionRef, scrollPos]);

  useEffect(() => {

    /** The operation is such that when the top part of the ball reaches the top of the window,

    it means it will be 0, so I added 300 so that it takes the value 0 almost in the middle of the screen
    and not at the top of the window. So if the top part of any year is equal to 0 (lengthYear),
    it means it wouldn't be visible or very far away, so it will be deactivated, but if it is greater than or equal to 0,
    it means it is in the most visible part of the window, so we will show it. */

    // agTimelineLineRef is the length of the line. I have to set it to end at the last ball.

    if (agTimelineItemRefs.current.length > 0) { //If Years exists

      for(let i = 0; i < agTimelineItemRefs.current.length; i++){
        
        const lengthYear = agTimelinePointRefs.current[i].getBoundingClientRect().top - 300;

        if(lengthYear <= 0){

              setActiveElements((prevActiveElements) => {
                if (!prevActiveElements.includes(i)) {
                  return [...prevActiveElements, i];
                }
                return prevActiveElements;
              });

          } else{ //If it is greater than the position of the element, it is removed.
            setActiveElements((prevActiveElements) => prevActiveElements.filter((el) => el !== i));
          } 
      }
      
    }
  }, [agTimelineItemRefs, agTimelinePointRefs, scrollPos]);

  return (
    <div ref={thirdSectionRef} id="thirdSection">
      <div className="timelineBlock">
        <div className="jsTimeline timeline" ref={agTimelineRef}>
          <div className="jsTimeline_line timeline_line" ref={agTimelineLineRef}>
            <div className="jsTimeline_line-progress timeline_line_progress" ref={agTimelineLineProgressRef}></div>
          </div>
          {/* <!--INFO LEFT--> */}
          <div ref={ref => agTimelineItemRefs.current[0] = ref} className={`jsTimelineExperience timelineItem ${activeElements.includes(0) ? 'js-ag-active' : ''}`}>
            <div className="timelineItemContainer">
            <div className="jsTimelineYear_circle timelineYear_circle" ref={el => (agTimelinePointRefs.current[0] = el)}>
                <div className="timelineYear">2024</div>
              </div>
            </div>

            <div className="experience">
              <div className="experienceContent" >
                <div className="experienceTitle">Full Stack Software Developer (Freelance)</div>
                <div className="experienceLocationTime"> 📍Melbourne, VIC, Australia. Jul 23 - Aug 24.</div>
                <div className="experienceDescription">
                  <p>I developed professional projects to address real-world challenges and enhance my skills. During this period, I worked on a web project for a client and developed the platform clockinsmart.com, implementing features such as user management and other key functionalities.</p>
                  <ul>
                    <li>Developing responsive and accessible user interfaces using HTML, CSS, and JavaScript.</li>
                    <li>Front-end with React, Bootstrap, and Chart.js; back-end with Express.js, MongoDB, and Redis.</li>
                    <li>Authentication, authorization, API integration, and email functionalities using SendGrid.</li>
                  </ul>
                </div>
              </div>
              <div className="timelineArrow"></div>
            </div>

          </div>
          {/*----------------------------------------------------------------------------------------------------------*/}
          {/* <!--INFO RIGHT--> */}
          <div ref={ref => agTimelineItemRefs.current[1] = ref} className={`jsTimelineExperience timelineItem ${activeElements.includes(1) ? 'js-ag-active' : ''}`}>
            <div className="timelineItemContainer">
            <div className="jsTimelineYear_circle timelineYear_circle" ref={el => (agTimelinePointRefs.current[1] = el)}>
                <div className="timelineYear">2023</div>
              </div>
            </div>
            <div className="experience">
              <div className="experienceContent" >
                <div className="experienceTitle">English student, Navitas</div>
                <div className="experienceLocationTime"> 📍Perth, WA, Australia. Feb 23 - Sep 23.</div>
                <div className="experienceDescription">
                  <p>During my English language learning experience, I enhanced all aspects of the English language in a multicultural environment. I decided to travel to Australia to improve my language skills, become more proficient, and overcome significant challenges. During that time, I also worked on personal projects to enhance my software development skills.</p>
                  <ul>
                    <li>Achievement of fluent conversational ability in English in both informal and formal situations.</li>
                    <li>Elevates my confidence in finding solutions in uncertain scenarios.</li>
                    <li>Enhances my networking skills in a multicultural environment.</li>
                  </ul>
                </div>
              </div>
              <div className="timelineArrow"></div>
            </div>
          </div>
          {/*----------------------------------------------------------------------------------------------------------*/}
			{/* <!--INFO LEFT--> MIDDLE*/}
      <div ref={ref => agTimelineItemRefs.current[2] = ref} className={`jsTimelineExperience timelineItem ${activeElements.includes(2) ? 'js-ag-active' : ''}`}>
				<div className="timelineItemContainer">
				  <div className="jsTimelineYear_circle timelineYear_circle"  ref={el => (agTimelinePointRefs.current[2] = el)}>
				  </div>
				</div>
				<div className="experience">
					<div className="experienceContent">
						<div className="experienceTitle">Software developer, Inescop</div>
						<div className="experienceLocationTime"> 📍Elda, Spain. Jun 22 - Jan 23.</div>
						<div className="experienceDescription">
							<p>For 8 months as a software developer, I focused on enhancing functionalities in C++ for a three-dimensional polygonal geometry modeling program. Additionally, I contributed to a dynamic team researching requirements, needs, and the integration of polygonal shoe geometries into different metaverses.</p>
							<ul>
								<li>Increase the functionality of a specific section for editing three-dimensional polygonal geometry.</li>
								<li>Enhance file type compatibility through the development of complex algorithms in C++.</li>
								<li>Meet requirements for inputting polygonal shoe geometries across different metaverses.</li>
							</ul>
						</div>
					</div>
				  <div className="timelineArrow"></div>
				</div>
			  </div>  
			  {/* <!----------------------------------------------------------------------------------------------------------> */}
       {/*  <!--INFO LEFT--> */}
       <div ref={ref => agTimelineItemRefs.current[3] = ref} className={`jsTimelineExperience timelineItem ${activeElements.includes(3) ? 'js-ag-active' : ''}`}>
				<div className="timelineItemContainer">
        <div className="jsTimelineYear_circle timelineYear_circle"  ref={el => (agTimelinePointRefs.current[3] = el)}>
					<div className="timelineYear">2022</div>
				  </div>
				</div>
				<div className="experience">
					<div className="experienceContent">
						<div className="experienceTitle">Bachelor's Degree in Computer Engineering in Information Technologies, Miguel Hernandez University</div>
						<div className="experienceLocationTime"> 📍Elche, Spain. Sep 17 - Jun 22.</div>
						<div className="experienceDescription">
							<p>Throughout my academic experience, I have acquired valuable knowledge in creating web pages using HTML, CSS, JavaScript, PHP, SQL, AJAX, and following the model-view-controller (MVC) method. I have developed algorithms in C++ and C to efficiently solve specific tasks and programmed applications with parallel algorithms using MPI and OpenMP. Additionally, I have created applications with Java and developed mobile apps using Android Studio and frameworks.</p>
							<ul>
								<li>Honors Degree in Mathematical Analysis.</li>
								<li>Score of perfect 10/10 for the Final Degree Project.</li>
							</ul>
						</div>
					</div>
					<div className="timelineArrow"></div>
				</div>
			  </div>
			  {/* <!----------------------------------------------------------------------------------------------------------> */}  
        </div>
        {/* /FUERA DEL TIMELINE/ */}
        {/* <!--INFO RIGHT--> */}
			  <div ref={ref => agTimelineItemRefs.current[4] = ref} className={`jsTimelineExperience timelineItem ${activeElements.includes(4) ? 'js-ag-active' : ''}`}>
				<div className="timelineItemContainer">
        <div className="jsTimelineYear_circle timelineYear_circle"  ref={el => (agTimelinePointRefs.current[4] = el)}>
					<div className="timelineYear">2021</div>
				  </div>
				</div>
				<div className="experience">
					<div className="experienceContent">
						<div className="experienceTitle">Data Science Research Scholar, Miguel Hernandez University</div>
						<div className="experienceLocationTime"> 📍Remote, Spain. Jan 21 - Sep 21.</div>
						<div className="experienceDescription">
							<p>In my 8-month AI research internship, I executed, tested, and documented Python-based descriptive and predictive models. Skilled in analyzing data patterns, achieving accurate predictions with an efficient time management</p>
							<ul>
								<li>Analyze patterns in the data to obtain conclusive results for the desired objectives.</li>
								<li>Achieve optimal predictions in various scenarios using machine learning algorithms.</li>
								<li>Enhanced time management for multiple tasks, ensuring timely completion.</li>
								<li>Developed proficiency in using version control software.</li>
							</ul>
						</div>
					</div>
				  <div className="timelineArrow"></div>
				</div>
			  </div> 
      </div>
    </div>
  );
};

export default Timeline;

