import React, {useEffect, useRef} from 'react';
import "../styles/About.css"

const ProfileSection = ({ profileSectionRef }) => {

    const article = useRef();

    useEffect(() => {
        
        const CONFIG = {
            proximity: 43,
            spread: 61,
            blur: 10,
            gap: 28,
            vertical: false,
            opacity: 0,
        };

        const isTouchEvent = (event) => {
          return event && (event.type.startsWith('touch') || event.touches);
        };

        const UPDATE = (event) => {
          
          if (isTouchEvent(event)) {
            return;
          }
            
            const ARTICLE_BOUNDS = article.current.getBoundingClientRect();
        
            if (
                event?.x > ARTICLE_BOUNDS.left - CONFIG.proximity &&
                event?.x < ARTICLE_BOUNDS.left + ARTICLE_BOUNDS.width + CONFIG.proximity &&
                event?.y > ARTICLE_BOUNDS.top - CONFIG.proximity &&
                event?.y < ARTICLE_BOUNDS.top + ARTICLE_BOUNDS.height + CONFIG.proximity
            ) {
              article.current.style.setProperty('--active', 1);
            } else {
              article.current.style.setProperty('--active', CONFIG.opacity);
            }
        
            const ARTICLE_CENTER = [
              ARTICLE_BOUNDS.left + ARTICLE_BOUNDS.width * 0.5,
              ARTICLE_BOUNDS.top + ARTICLE_BOUNDS.height * 0.5,
            ];
        
            let ANGLE = (Math.atan2(event?.y - ARTICLE_CENTER[1], event?.x - ARTICLE_CENTER[0]) * 180) / Math.PI;
            ANGLE = ANGLE < 0 ? ANGLE + 360 : ANGLE;
        
            article.current.style.setProperty('--start', ANGLE + 90);
            
        };

        const isTouchScreen = window.matchMedia('(hover: none) and (pointer: coarse)').matches;
        
        if (!isTouchScreen) {
          document.body.addEventListener('pointermove', UPDATE);
          UPDATE();
        }
        
        return () => {
            if (!isTouchScreen) {
              document.body.removeEventListener('pointermove', UPDATE);
            }
        };
    }, []); 

  return (
    <section ref={profileSectionRef} id="about" className="section">
      <div className="container">
        <article ref={article}>
          <div className="glows"></div>
          <div id="contentAboutMe">
            <div className="column" id="leftColumn">
              <div id="leftTopRow">
                <div className="profileImageContainer">
                  <img src="/MyPhoto.png" alt="Tu imagen" className="profileImage" />
                </div>
              </div>
              <div id="leftBottomRow">
              <h2>Contact Information</h2>
              <div className="contact-details">
                <p>Julio Montesinos Alvarado</p>
                <p>Luxembourg</p>
                <p>+32 475 291 724</p>
                <p>juliomontesinosalvarado@gmail.com</p>
              </div>
              <a href="/CV_Julio_Montesinos.pdf" download="CV_Julio_Montesinos.pdf">
                <button className="download-button">Download Resume</button>
              </a>
            </div>

            </div>
            <div className="column" id="rightColumn">
              <h1>About me</h1>
              <p>Hi, I’m Julio, a passionate software developer specializing in web technologies. I graduated in Computer Engineering from Miguel Hernández University, and since then, I’ve been working with a wide range of technologies to create dynamic, functional, and user-friendly web applications.</p>
              <p>My expertise includes HTML, CSS, JavaScript, React, TypeScript, and PHP, among other technologies, which I use to build both front-end and full-stack projects. I also gained a solid foundation in data science and web development while working at the Department of Statistics, Mathematics, and Computer Science at UMH, where I developed a web application for managing programming courses. This project involved both front-end and back-end development, including database management, security, and user authentication. Additionally, I worked with machine learning algorithms, performing tasks such as data preprocessing, developing predictive models, and documenting the results using platforms like Kaggle and Google Colab.</p>
              <p>In a previous role, I contributed to enhancing C++ functionalities for a 3D modeling program, which further sharpened my technical skills. This, combined with my passion for web development, led me to create ClockIn Smart, a platform designed to help casual workers track their hours easily and efficiently, along with my personal portfolio.</p>
              <p>During my time in Australia, I improved my English communication, strengthened my soft skills, and deepened my knowledge of web frameworks. I also worked as a freelance developer, collaborating with a client to create and maintain a medical web project, which further expanded my adaptability and problem-solving skills.</p>
              <p>I’m a proactive problem-solver with a strong focus on continuous learning and growth. Whether in front-end or full-stack development, I’m excited to bring my technical skills, creativity, and dedication to a new team and contribute meaningfully to every project. 🚀</p>
          </div>
          </div>
        </article>
      </div>
    </section>

  );
}

export default ProfileSection;